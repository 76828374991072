export const ALL_LOCALES = ['en', 'es', 'zh', 'ru', 'th'] as const;

export type AppLocales = typeof ALL_LOCALES[number];

export const LOCALES_LABELS: Record<AppLocales, string> = {
    en: 'English (US)',
    es: 'Español (ES)',
    zh: '普通话 (CH)',
    ru: 'русский (RU)',
    th: 'ไทย (TH)',
};
