import { ImageAsset } from '@/components/atom/Image';
import { WalletType } from '@/state/wallet';
import { ENV_APP_URL } from './env';

export interface Wallet {
    _id: WalletType;
    name: string;
    downloadUrl: string;
    asset: ImageAsset;
    isAvailable: () => boolean;
    inMainnet: boolean;
}

export const WALLET_LIST: Wallet[] = [
    {
        _id: 'metamask',
        name: 'MetaMask',
        downloadUrl: `https://metamask.app.link/dapp/${ENV_APP_URL || 'app.claystack.com'}`,
        asset: 'metamask',
        inMainnet: true,
        isAvailable: () =>
            typeof window !== 'undefined' &&
            typeof window.ethereum !== 'undefined' &&
            window.ethereum.isMetaMask,
    },

    {
        _id: 'coinbase',
        name: 'Coinbase',
        downloadUrl: '#',
        asset: 'coinbase',
        inMainnet: true,
        isAvailable: () => true,
    },
    {
        _id: 'wallet_connect',
        name: 'Wallet Connect',
        downloadUrl: '#',
        asset: 'wallet-connect',
        inMainnet: true,
        isAvailable: () => true,
    },
    {
        _id: 'unstoppable_domains',
        name: 'Unstoppable Domains',
        downloadUrl: '#',
        asset: 'unstoppable-domains',
        inMainnet: true,
        isAvailable: () => true,
    },
];
