import { EXTERNAL_URLS } from '@/constants/urls';
import { useLanguage } from '@/hooks/language';
import { useMixpanel } from '@/hooks/mixpanel';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { FiChevronUp } from 'react-icons/fi';
import { LanguageSelector } from './molecule/LanguageSelector';

export const Footer: FC = () => {
    const router = useRouter();
    const isMissionHubPage = router.asPath === '/mission-hub';
    const { track } = useMixpanel();

    const { selectedLanguage } = useLanguage();

    return (
        <footer className="container flex justify-between px-4 mx-auto mt-auto text-grey-20">
            <ul className="flex py-2 space-x-4 md:space-x-8 md:t__caption--c1 t__button--u2">
                <li>
                    <a
                        href={EXTERNAL_URLS.termsAndConditions}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-white"
                        onClick={() => track('FOOTER-LINK1')}
                    >
                        Terms &amp; Conditions
                    </a>
                </li>
                <li>
                    <a
                        href={EXTERNAL_URLS.riskDisclosure}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-white"
                        onClick={() => track('FOOTER-LINK2')}
                    >
                        Risk Disclosure
                    </a>
                </li>
            </ul>
            {!isMissionHubPage && (
                <div className="relative group">
                    <button
                        id="lang-selector-btn"
                        aria-label="Choose language"
                        onClick={() => track('LANGUAGE SELECTOR')}
                        className="flex items-center gap-1 px-2 py-1 my-1 rounded-md group md:t__caption--c1 t__button--u2 text-grey-20 hover:text-white"
                    >
                        <span>{selectedLanguage}</span>
                        <FiChevronUp className="transition-transform pointer-events-none group-hover:rotate-180" />
                    </button>
                    <div className="absolute right-0 invisible p-2 mt-2 space-y-1 transition shadow-lg dropdown__container sub-menu ease-spring group-hover:visible z-select-option bottom-8 rounded-xl">
                        <LanguageSelector onLangSelected={() => {}} isLanguageMenuOpen={true} />
                    </div>
                </div>
            )}
        </footer>
    );
};
