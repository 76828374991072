import { appEventsAtom, Events } from '@/state/app';
import { useRecoilState } from 'recoil';

export const useEvents = () => {
    const [events, setEvents] = useRecoilState(appEventsAtom);
    const pushEvent = (event: Events, value = Date.now()) =>
        setEvents((previous) => ({ ...previous, [event]: value }));

    return {
        events,
        pushEvent,
    };
};
