import { appThemeAtom } from '@/state/app';
import { Maybe, Theme } from '@/utils/common';
import classNames from 'classnames';
import NextImage from 'next/image';
import { useCallback, useMemo, useState } from 'react';
import { ensuredForwardRef } from 'react-use';
import { useRecoilValue } from 'recoil';

export type ImageAsset =
    | 'metamask'
    | 'wallet-connect'
    | 'coinbase'
    | 'unstoppable-domains'
    | 'claystack-victoria'
    | 'claystack-victoria-mobile'
    | 'claystack-logo'
    | 'claystack-logo-mobile'
    | 'ethereum'
    | 'matic'
    | 'matic-alt'
    | 'matic-main'
    | 'cs-matic'
    | 'grt'
    | 'grt-main'
    | 'cs-grt'
    | 'ftm'
    | 'ftm-alt'
    | 'ftm-main'
    | 'cs-ftm'
    | 'distribution'
    | 'farming'
    | 'loader'
    | 'diamond'
    | 'coin'
    | 'fire-emoji'
    | 'swap--dai'
    | 'swap--ethereum'
    | 'swap--sand'
    | 'swap--usdt'
    | 'swap--wmatic'
    | 'swap--mai'
    | 'swap--cxeth'
    | 'swap--wbtc'
    | 'swap--usdc'
    | 'swap--qi'
    | 'swap--dg';

type ImageProps = {
    asset: ImageAsset;
    className?: string;
    width?: number;
    height?: number;
    theme?: Theme;
    isIcon?: boolean;
    onClick?: () => void;
};
export const Image = ensuredForwardRef<HTMLDivElement, ImageProps>((props, ref) => {
    const appTheme = useRecoilValue(appThemeAtom);
    const [assetUrl, setAssetUrl] = useState<Maybe<string>>(null);

    const THEME_ICONS = useMemo<ImageAsset[]>(() => ['cs-matic', 'cs-grt', 'cs-ftm', 'loader'], []);
    const PNG_ICONS = useMemo<ImageAsset[]>(() => ['coinbase', 'fire-emoji'], []);
    const isThemedIcon = useCallback(
        () => THEME_ICONS.includes(props.asset),
        [THEME_ICONS, props.asset],
    );

    useMemo(() => {
        const theme = 'dark'; // props.theme ? props.theme : appTheme;
        const chunks = [
            props.isIcon ? 'icon__' : '',
            props.asset,
            props.isIcon && !isThemedIcon() ? '' : `--${theme}`,
        ];
        const extension = PNG_ICONS.includes(props.asset) ? 'png' : 'svg';
        const url = `/images/${chunks.join('')}.${extension}`;
        setAssetUrl(url);
    }, [appTheme, isThemedIcon, setAssetUrl, props.theme, props.isIcon, props.asset]);

    return (
        <div
            className={classNames('flex items-center', props.className)}
            ref={ref}
            onClick={props.onClick}
            style={{
                minWidth: props.width,
                minHeight: props.height,
            }}
        >
            {assetUrl && (
                <NextImage
                    className={props.className}
                    src={assetUrl}
                    width={props.width}
                    height={props.height}
                    alt={props.asset}
                />
            )}
        </div>
    );
});
Image.defaultProps = {
    isIcon: false,
    width: 16,
    height: 16,
};
