import { ImageAsset } from '@/components/atom/Image';
import { ContractContext as ClayExchangeContext } from '@/utils/contracts/abi/ClayExchange';
import { ContractContext as ClayFaucetContext } from '@/utils/contracts/abi/ClayFaucet';
import { ContractContext as ClayFTMContext } from '@/utils/contracts/abi/ClayFTM';
import { ContractContext as ClayGraphContext } from '@/utils/contracts/abi/ClayGraph';
import { ContractContext as ClayManagerContext } from '@/utils/contracts/abi/ClayManager';
import { ContractContext as ClayMaticContext } from '@/utils/contracts/abi/ClayMatic';
import { ContractContext as ClaySwapContext } from '@/utils/contracts/abi/ClaySwap';
import { ContractContext as CsTokenContext } from '@/utils/contracts/abi/CsToken';
import { ContractContext as TokenContext } from '@/utils/contracts/abi/Token';
import { BigNumber, ContractInterface, ethers, Event } from 'ethers';

export type Token = 'MATIC' | 'GRT' | 'MATIC_POLYGON' | 'FTM';
export type ERC20Token =
    | 'DAI'
    | 'W_MATIC'
    | 'USDT'
    | 'USDC'
    | 'SAND'
    | 'WETH'
    | 'miMATIC'
    | 'cxETH'
    | 'WBTC'
    | 'USDC'
    | 'QUICK'
    | 'QI'
    | 'DG';

export enum Environment {
    mainnet = 'mainnet',
    testnet = 'testnet',
    localhost = 'localhost',
}

export type NetworkFamily = 'ethereum' | 'polygon' | 'fantom';
export type Network =
    | 'goerli'
    | 'rinkeby'
    | 'localhost'
    | 'mainnet'
    | 'mumbai'
    | 'polygon'
    | 'fantom_testnet'
    | 'fantom';
export type Contract =
    | 'Token'
    | 'CsToken'
    | 'ClayMain'
    | 'ClayFaucet'
    | 'ClaySwap'
    | 'ClayManager'
    | 'CsTokenV1'
    | 'ClayExchange';
export type Version = 'victoria' | 'malawi' | 'v1';
export type ReadOnlyProvider =
    | ethers.providers.BaseProvider
    | ethers.providers.Web3Provider
    | ethers.providers.JsonRpcProvider;

export type ProviderOrSigner = ethers.Signer | ReadOnlyProvider;

export type ContractRecord<T> = {
    [N in Network]?: {
        [C in Contract]?: T;
    };
};

export type NetworkRecord<T> = {
    [N in Network]?: T;
};

export interface NetworkFamilyConfig {
    name: string;
    icon: {
        asset: ImageAsset;
        width: number;
        height: number;
    };
}
export interface NetworkConfig {
    _id: Network;
    explorer: string;
    chainId: number;
    name: string;
    clayStackNode: string;
    family: NetworkFamilyConfig;
    currency: string;
}

export interface TokenVocab {
    _id: Token;
    slug: string;
    name: string;
    symbol: string;
    csSymbol: string;
    inMainnet: boolean;
    isChild: boolean;
    parentId: Token;
    isNative: boolean;
    styles: {
        backgroundImage: string;
        backgroundColor: string;
        buttonColor: string;
    };
    isChartEnabled: boolean;
    apr: number;
}

export interface TokenConfig {
    testnet: NetworkConfig;
    mainnet: NetworkConfig;
    vocab: TokenVocab;
    assets: Record<string, ImageAsset>;
    checkpointTime: Record<string, number>;
    cgId: string;
    address: ContractRecord<string>;
    abi: ContractRecord<() => Promise<{ default: ContractInterface }>>;
    version: NetworkRecord<Version>;
}

export type CONTRACT_CONTEXT = {
    MATIC_POLYGON: {
        Token: TokenContext;
        CsToken: CsTokenContext;
        CsTokenV1: CsTokenContext;
        ClayMain: ClayMaticContext;
        ClayFaucet: ClayFaucetContext;
        ClayExchange: ClayExchangeContext;
        ClaySwap: ClaySwapContext;
        ClayManager: ClayManagerContext;
    };
    MATIC: {
        Token: TokenContext;
        CsToken: CsTokenContext;
        ClayMain: ClayMaticContext;
        ClayFaucet: ClayFaucetContext;
        ClaySwap: ClaySwapContext;
    };
    GRT: {
        Token: TokenContext;
        CsToken: CsTokenContext;
        ClayMain: ClayGraphContext;
        ClayFaucet: ClayFaucetContext;
        ClaySwap: ClaySwapContext;
    };
    FTM: {
        Token: TokenContext;
        CsToken: CsTokenContext;
        ClayMain: ClayFTMContext;
        ClayFaucet: ClayFaucetContext;
        ClaySwap: ClaySwapContext;
    };
};

export type CONTRACT_CONTEXT_V2 = {
    Token: TokenContext;
    CsToken: CsTokenContext;
    CsTokenV1: CsTokenContext;
    ClayMain: ClayMaticContext | ClayGraphContext | ClayFTMContext;
    ClayFaucet: ClayFaucetContext;
    ClayExchange: ClayExchangeContext;
    ClaySwap: ClaySwapContext;
    ClayManager: ClayManagerContext;
};

export type TypedEvent<T extends unknown> = Omit<Event, 'args'> & {
    args: T;
};

export type TokenInfo = {
    tvl: BigNumber;
    apy: number;
    usdPrice: number;
};

export type SwapToken = {
    token: ERC20Token | null;
    image: ImageAsset;
    label: string;
    decimals: number;
    address: Partial<Record<Network, string | null>>;
    isOnlyMainnet: boolean;
};
