import { ENV_UNSTOPPABLE_DOMAINS_ID, ENV_UNSTOPPABLE_DOMAINS_REDIRECT } from '@/constants/env';
import { NETWORK_CONFIG, RPC_URLS } from '@/constants/tokens';
import { UAuthConnector } from '@uauth/web3-react';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

const rpc = Object.values(NETWORK_CONFIG).reduce((acc, config) => {
    acc[config.chainId] = RPC_URLS[config._id][0];
    return acc;
}, {} as Record<number, string>);

export const metamaskConnector = new InjectedConnector({});

export const walletConnectConnector = new WalletConnectConnector({
    rpc,
    bridge: 'https://bridge.walletconnect.org',
    qrcode: true,
});

export const coinbaseConnector = new WalletLinkConnector({
    url: rpc[5],
    appName: 'ClayStack',
    darkMode: true,
});

export const uauthConnector = new UAuthConnector({
    clientID: ENV_UNSTOPPABLE_DOMAINS_ID,
    redirectUri: ENV_UNSTOPPABLE_DOMAINS_REDIRECT,
    scope: 'openid wallet',
    connectors: { injected: metamaskConnector, walletconnect: walletConnectConnector },
});
