import {
    ENV_APP_URL,
    ENV_DOCUMENTATION_APP_URL,
    ENV_TESTNET_APP_URL,
    ENV_WEB_DOMAIN,
    ENV_WEB_URL,
} from './env';

export const EXTERNAL_URLS = {
    web: ENV_WEB_URL || 'https://claystack.com/',
    riskDisclosure: (ENV_WEB_URL || 'https://claystack.com/') + 'risks',
    termsAndConditions: (ENV_WEB_URL || 'https://claystack.com/') + 'terms',
    privacyPolicy: (ENV_WEB_URL || 'https://claystack.com/') + 'privacy',
    app: ENV_APP_URL || 'https://app.claystack.com/',
    testnet: ENV_TESTNET_APP_URL || 'https://victoria.claystack.com/',
    documentation: ENV_DOCUMENTATION_APP_URL || 'https://docs.claystack.com/',
    faq: 'https://docs.claystack.com/faq/staking',
    domain: ENV_WEB_DOMAIN || 'app.claystack.com',
    ambassador: 'https://2vrg4s8dqhy.typeform.com/to/FaaNV0S3',
    blog: 'https://blog.claystack.com/',
    careers:
        'https://www.notion.so/claystack/ClayStack-We-re-Hiring-f6cb7aed02944255b4d6c737829b658c',
    social: {
        twitter: 'https://twitter.com/claystack_hq',
        telegram: 'https://t.me/claystack',
        discord: 'https://discord.gg/z5npACzdfn',
    },
    maticTestnetCheckpoint: 'https://wallet-dev.polygon.technology/staking/',
    maticMainnetCheckpoint: 'https://wallet.polygon.technology/staking/',
    helpCenterContact: 'https://discord.gg/z5npACzdfn',
    faucet: {
        ftm: 'https://faucet.fantom.network/',
    },
};
