import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';
import { isDev } from './common';
import { ErrorContext } from './error';

Sentry.init({
    dsn: isDev()
        ? undefined
        : 'https://34e15f4516f54b70bfab7856d73d89f5@o1076637.ingest.sentry.io/6078651',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});

export const captureMetamaskError = (
    error: Error,
    context: ErrorContext,
    info: Record<string, string> = {},
) => {
    if (isDev()) return;
    Sentry.captureException(error, {
        contexts: {
            metamask: {
                action: context,
                ...info,
            },
        },
    });
};
