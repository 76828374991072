import classNames from 'classnames';
import { useRouter } from 'next/router';
import { MouseEventHandler } from 'react';

export type NavItemProps = {
    label: string;
    icon: React.ReactNode;
    href?: string;
    isExternal?: boolean;
    hasNestedMenu?: boolean;
    className?: string;
    isVisible?: boolean;
    id?: string;
    locale?: string;
    onClick?: MouseEventHandler;
    onNestedMenuClick?: () => void;
};
export const NavItem = ({
    label,
    icon,
    href,
    isExternal = false,
    hasNestedMenu = false,
    className,
    isVisible = true,
    id,
    locale,
    onClick,
    onNestedMenuClick,
}: NavItemProps) => {
    const _href = href ?? undefined;
    const target = href && isExternal ? '_blank' : undefined;
    const router = useRouter();
    const _handleClick: MouseEventHandler = (e) => {
        if (hasNestedMenu) {
            onNestedMenuClick!();
        } else {
            onClick?.(e);
        }
    };
    const _onClick: MouseEventHandler = (e) => {
        const isLink = href !== undefined;
        const isNextLink = isLink && !isExternal;
        if (isNextLink) {
            e.preventDefault();
            router.push(href!, undefined, { locale: locale });
        } else if (!isLink) {
            _onClick?.(e);
        }
    };
    return isVisible ? (
        <a
            className={classNames(
                'cursor-pointer t__button--u1 justify-between items-center p-2 rounded-lg hover:bg-[#4d3964] flex',
                className,
            )}
            role="button"
            onClick={_handleClick}
            href={_href}
            target={target}
            id={id}
        >
            <span>{label}</span>
            {icon}
        </a>
    ) : null;
};
