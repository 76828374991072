import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useClickAway } from 'react-use';

export type SelectDropdownProps = {
    className?: string;
    body?: React.ReactNode;
    children?: React.ReactNode;
    isReadonly: boolean;
    prefix: React.ReactNode;
    hideTextOnMobile?: boolean;
    title: string;
    label?: string;
    titleStyle?: string;
    variant?: 'base' | 'pill';
    bodyStyle?: string;
    id?: string;
};
export const SelectDropdown = ({
    className,
    children,
    body,
    isReadonly,
    prefix,
    hideTextOnMobile,
    title,
    label,
    titleStyle,
    variant = 'base',
    bodyStyle,
    id,
}: SelectDropdownProps) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const selectEl = useRef(null);

    const dropdownEl = useRef(null);
    useClickAway(dropdownEl, (e) => {
        if (e.target != dropdownEl.current && e.target != selectEl.current) {
            e.stopPropagation();
            setDropdownOpen(false);
        }
    });

    const handleSelectClick = () => setDropdownOpen((isOpen) => !isOpen);

    const titleBody = () => {
        return (
            <div className={classNames(titleStyle, 'pointer-events-none')}>
                {hideTextOnMobile ? (
                    <div className="hidden md:block">{title}</div>
                ) : (
                    <div>{title}</div>
                )}
            </div>
        );
    };
    return (
        <div className="relative">
            <button
                id={id}
                aria-label={title}
                className={classNames(
                    'relative t__button--u1 flex items-center gap-1 md:gap-2 ring-1 ring-transparent disabled:pointer-events-none',
                    {
                        'rounded-lg p-2 bg-grey-50 hover:bg-grey-60': variant === 'base',
                        'rounded-full p-1 disabled:pr-2 bg-main-50 hover:bg-main-60 text-white disabled:text-grey-10 shadow-none disabled:shadow-none disabled:ring-[#4d3964] disabled:bg-transparent':
                            variant === 'pill',
                        'ring-grey-50/20': isDropdownOpen,
                    },
                )}
                ref={selectEl}
                onClick={handleSelectClick}
                disabled={isReadonly}
            >
                {prefix}
                {titleBody()}
                {!isReadonly ? (
                    <FiChevronDown
                        className={classNames('transition-transform pointer-events-none', {
                            'rotate-180': isDropdownOpen,
                        })}
                    />
                ) : null}
            </button>
            <AnimatePresence>
                {isDropdownOpen && (
                    <motion.div
                        initial={{ opacity: 0, y: -25 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -25 }}
                        className={classNames('dropdown__container absolute p-2 z-50 mt-2', {
                            'rounded-lg': variant === 'base',
                            'rounded-xl': variant === 'pill',
                        })}
                        onClick={handleSelectClick}
                        ref={dropdownEl}
                    >
                        {label && <div className="t__body--b3 text-grey-10">{label}</div>}
                        <div className={bodyStyle}>{body ? body : children}</div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};
