import Link from 'next/link';

import { DefaultProps } from '@/utils/common';

export type AnchorProps = DefaultProps<typeof Link> & {
    href: string;
    caption: string;
    className?: string;
    isNextLink?: boolean;
    isExternal?: boolean;
    id?: string;
    onClick?: () => void;
};
export const Anchor = ({
    href,
    caption,
    className,
    isNextLink = false,
    isExternal = false,
    id,
    onClick = undefined,
}: AnchorProps) => {
    return !isNextLink ? (
        <a
            href={href}
            className={className}
            target={isExternal ? '_blank' : '_self'}
            rel={isExternal ? 'noreferrer' : undefined}
            id={id}
            onClick={onClick}
        >
            {caption}
        </a>
    ) : (
        <span className={className} id={id}>
            <Link href={href}>{caption}</Link>
        </span>
    );
};
