import { appDataAtom, appModalManager } from '@/state/app';
import { walletAtom } from '@/state/wallet';
import { areSameAddress } from '@/utils/common';
import dynamic from 'next/dynamic';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

const WalletModal = dynamic(import('@/components/modals/WalletModal'));
const WalletError = dynamic(import('@/components/modals/WalletError'));
const SwapModal = dynamic(import('@/components/modals/SwapModal'));

export const ModalManager = () => {
    const [modal, setModal] = useRecoilState(appModalManager);
    const walletState = useRecoilValue(walletAtom);
    const data = useRecoilValue(appDataAtom);

    const isValidWallet = useMemo(() => {
        const { walletType, walletLoading, selectedAddress, unstoppableDomains } = walletState;
        if (walletLoading) return true;
        if (walletType !== 'unstoppable_domains') return true;
        if (!unstoppableDomains?.wallet_address || !selectedAddress) return true;
        return areSameAddress(unstoppableDomains?.wallet_address, selectedAddress);
    }, [walletState]);

    useEffect(() => {
        let m = null;
        if (!isValidWallet) {
            m = 'wallet-error';
        } else if (data.csTokenV1Balance.gt(0)) {
            m = 'swap';
        }
        // @ts-ignore
        setModal(m);
    }, [isValidWallet, data.csTokenV1Balance]);

    const getModal = () => {
        switch (modal) {
            case 'swap':
                return (
                    <SwapModal
                        isVisible={true}
                        isClosable={false}
                        balance={data.csTokenV1Balance}
                    />
                );
            case 'connect':
                return <WalletModal isVisible={true} onClose={() => setModal(null)} />;
            case 'wallet-error':
                return (
                    <WalletError
                        isVisible={true}
                        onClose={() => setModal(null)}
                        userAddress={walletState.unstoppableDomains?.wallet_address ?? ''}
                        domain={walletState.unstoppableDomains?.sub ?? ''}
                    />
                );
            default:
                return null;
        }
    };

    return <>{getModal()}</>;
};
