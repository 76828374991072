import { Anchor, AnchorProps } from '@/components/atom/Anchor';
import { Image } from '@/components/atom/Image';
import { appPointsAtom } from '@/state/app';
import { useRecoilValue } from 'recoil';
import { Button } from '../atom/Button';
import { WalletInfoBox } from '../organism/WalletInfoBox';

export const MissionHubNav = () => {
    const { standingData } = useRecoilValue(appPointsAtom);
    const navRouterItems: AnchorProps[] = [
        { href: '/mission-hub', caption: 'Missions', isNextLink: true },
        { href: '/mission-hub/leaderboard', caption: 'Leaderboard', isNextLink: true },
    ];
    return (
        <div className="flex items-center w-full">
            <div className="flex items-center gap-4 ml-6 mr-auto">
                <div className="flex items-center gap-4">
                    {navRouterItems.map((anchor, index) => (
                        <Anchor className="nav__item_missionhub--flat" key={index} {...anchor} />
                    ))}
                </div>
            </div>
            <div className="flex items-center gap-6">
                <Button
                    className="pl-10"
                    variant="secondary"
                    isPrefixNonPadding
                    prefix={<Image asset="diamond" width={36} height={36} isIcon={true} />}
                >
                    <span className="text-grey-10 t__button--u1">{Math.round(standingData.diamonds)}</span>
                </Button>
                <Button
                    className="pl-10"
                    variant="secondary"
                    isPrefixNonPadding
                    prefix={<Image asset="coin" width={36} height={36} isIcon={true} />}
                >
                    <span className="text-grey-10 t__button--u1">{Math.round(standingData.total_points)}</span>
                </Button>
                <WalletInfoBox className="hidden md:inline-block" />
            </div>
        </div>
    );
};
