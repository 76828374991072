import { Transaction } from '@/components/transactions/TransactionAction';
import { LocalStorageKeys } from '@/constants/local-storage';
import { useAlerts } from '@/hooks/alerts';
import { useEvents } from '@/hooks/events';
import { MixPanelContractEvent, MixPanelEvents, useMixpanel } from '@/hooks/mixpanel';
import { useWallet } from '@/hooks/wallet';
import { appEventsAtom, appTransactionsAtom, Events } from '@/state/app';
import { walletAtom } from '@/state/wallet';
import { formatEther } from '@/utils/common';
import { getNetworkByChainId } from '@/utils/utils';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from './translate';

export const useTransactionsManager = () => {
    const { track } = useMixpanel();
    const { t } = useTranslation();
    const appEvents = useRecoilValue(appEventsAtom);
    const walletState = useRecoilValue(walletAtom);
    const [transactions, setTransactions] = useRecoilState(appTransactionsAtom);
    const { pushEvent } = useEvents();
    const { alertError, alertSuccess } = useAlerts();
    const { provider } = useWallet();
    const [value, setValue] = useLocalStorage(LocalStorageKeys.TRANSACTIONS, undefined, {
        raw: false,
        serializer: (value: any) => JSON.stringify(value),
        deserializer: (value: any) => JSON.parse(value),
    });
    const [polling, setPolling] = useState<NodeJS.Timer | null>();

    // Initial load from disk
    useEffect(() => {
        if (value && value.length > 0) setTransactions(value);
    }, []);

    // Set-up polling for transactions
    useEffect(() => {
        if (transactions.length > 0 && !polling) {
            const id = setInterval(function () {
                pushEvent(Events.transactionEvent, Date.now());
            }, 3000);
            setPolling(id);
        } else if (transactions.length == 0 && polling) {
            clearInterval(polling);
            setPolling(null);
        }
    }, [transactions]);

    // Checks transactions
    useEffect(() => {
        if (transactions.length > 0) checkTransactions();
    }, [appEvents.transactionEvent]);

    const getNewPendingHashes = async (pending: Transaction[]) => {
        const newPendingHashes: string[] = [];
        for (const tx of pending) {
            if (
                tx.chainId === walletState.chainId &&
                tx.address.toLocaleLowerCase() === walletState.selectedAddress?.toLocaleLowerCase()
            ) {
                let receipt = await provider!.getTransaction(tx.hash);
                if (!receipt || receipt.confirmations > 0) {
                    const amount = tx?.params?.amount ? +formatEther(tx.params.amount) : undefined;
                    let eventName: MixPanelEvents = 'Flash exit';
                    const networkConfig = getNetworkByChainId(tx.chainId);
                    const eventParams: MixPanelContractEvent = {
                        Status: 'Failure',
                        'Transaction hash': tx.hash,
                        Network: networkConfig.name,
                        'Explorer URL': `${networkConfig.explorer}${tx.hash}`,
                        Token: tx.token,
                    };
                    if (receipt && receipt!.confirmations > 0) {
                        const r = await provider!.getTransactionReceipt(tx.hash);
                        if (r.status == 0) {
                            alertError(
                                //@ts-expect-error: template string interpolation does not play well when you have defined keys
                                t(`failed.${tx.event}`, { token: tx.secondaryToken || tx.token }),
                                tx.hash,
                            );
                        } else {
                            alertSuccess(
                                //@ts-expect-error: template string interpolation does not play well when you have defined keys
                                t(`success.${tx.event}`, { token: tx.secondaryToken || tx.token }),
                                tx.hash,
                            );
                            eventParams.Status = 'Success';
                            pushEvent(tx.event);
                        }
                    } else {
                        alertError(
                            //@ts-expect-error: template string interpolation does not play well when you have defined keys
                            t(`failed.${tx.event}`, { token: tx.secondaryToken || tx.token }),
                            tx.hash,
                        );
                    }
                    switch (tx.event) {
                        case Events.allowEvent:
                            eventName = 'Allow token';
                            break;
                        case Events.stakeEvent:
                            eventName = 'Stake token';
                            eventParams.Amount = amount;
                            break;
                        case Events.unstakeEvent:
                            eventName = 'Unstake token';
                            eventParams.Amount = amount;
                            break;
                        case Events.claimEvent:
                            eventName = 'Claim token';
                            eventParams.Amount = amount;
                            break;
                        case Events.flashExitEvent:
                            eventName = 'Flash exit';
                            eventParams.Amount = amount;
                            break;
                        case Events.faucetEvent:
                            eventName = 'Faucet collect';
                            eventParams.Amount = amount;
                    }
                    track(eventName, eventParams);
                    continue;
                }
            }
            newPendingHashes.push(tx.hash);
        }
        return newPendingHashes;
    };

    const checkTransactions = async () => {
        if (provider) {
            const hashes = await getNewPendingHashes(transactions);
            const newPending = transactions.filter((x) => hashes.includes(x.hash));
            setTransactions(newPending);
            setValue(newPending);
        }
    };

    return {};
};

export const useTransactions = () => {
    const { provider } = useWallet();
    const { pushEvent } = useEvents();
    const [transactions, setTransactions] = useRecoilState(appTransactionsAtom);
    const [value, setValue] = useLocalStorage(LocalStorageKeys.TRANSACTIONS, undefined, {
        raw: false,
        serializer: (value: any) => JSON.stringify(value),
        deserializer: (value: any) => JSON.parse(value),
    });

    const waitTx = async (hash: string) => {
        await provider?.waitForTransaction(hash);
        pushEvent(Events.transactionEvent);
    };

    const pushTx = (transaction: Transaction) => {
        if (transaction.hash) {
            const newList = [...transactions, transaction];
            setTransactions(newList);
            setValue(newList);
            waitTx(transaction.hash);
        }
    };

    return { pushTx };
};
