import { appHelpCenterAtom } from '@/state/app';
import faqList from '@/utils/help-center/generated-faqs.json';
import Fuse from 'fuse.js';
import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { isNull, Maybe } from '../utils/common';
export const useHelpCenterState = () => {
    const [helpCenterState, setHelpCenterState] = useRecoilState(appHelpCenterAtom);

    const openCenter = () => setHelpCenterState((previous) => ({ ...previous, isOpen: true }));
    const closeCenter = () => setHelpCenterState((previous) => ({ ...previous, isOpen: false }));
    const toggleCenter = () =>
        setHelpCenterState((previous) => ({ ...previous, isOpen: !previous.isOpen }));

    return { helpCenterState, openCenter, closeCenter, toggleCenter };
};

export type FAQItem = {
    id: string;
    title: string;
    html: string;
    description?: string;
    content?: string;
    isEmbed?: boolean;
    youtubeId?: string;
};
export const useHelpCenterItems = (searchFilter: Maybe<string>) => {
    const fuse = new Fuse(faqList, {
        includeScore: true,
        includeMatches: true,
        minMatchCharLength: 2,
        keys: [
            { name: 'title', weight: 2 },
            { name: 'content', weight: 1 },
        ],
    });

    const filteredList = useMemo(() => {
        if (isNull(searchFilter)) return faqList;
        const result = fuse.search(searchFilter);
        return result.map((r) => r.item);
    }, [searchFilter]);

    const hasNoResults = useMemo(
        () => !isNull(searchFilter) && filteredList.length === 0,
        [filteredList, searchFilter],
    );

    return {
        list: filteredList,
        hasNoResults,
    };
};
