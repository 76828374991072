import { useWallet } from '@/hooks/wallet';
import { appConfigAtom, appModalManager } from '@/state/app';
import { walletAtom, walletIsConnected, walletIsValidNetwork } from '@/state/wallet';
import { truncateAddress } from '@/utils/common';
import { getAssetByWallet, getNetworkByToken } from '@/utils/utils';
import { useRouter } from 'next/router';
import { FC, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { match, P } from 'ts-pattern';
import { Button } from '../atom/Button';
import { Image } from '../atom/Image';

interface WalletInfoBoxProps {
    className?: string;
}
export const WalletInfoBox: FC<WalletInfoBoxProps> = (props) => {
    const router = useRouter();
    const walletState = useRecoilValue(walletAtom);
    const appConfig = useRecoilValue(appConfigAtom);
    const isWalletConnected = useRecoilValue(walletIsConnected);
    const isWalletValidNetwork = useRecoilValue(walletIsValidNetwork);
    const setModal = useSetRecoilState(appModalManager);
    const hasConnected = isWalletConnected;
    const hasValidNetwork = hasConnected && (!router.query.slug || isWalletValidNetwork);
    const { switchNetwork } = useWallet();

    const walletInfoText = useMemo(() => {
        return match(walletState)
            .with(
                {
                    walletType: 'unstoppable_domains',
                },
                ({ unstoppableDomains, selectedAddress }) =>
                    unstoppableDomains?.sub ?? truncateAddress(selectedAddress, 6),
            )
            .with(
                {
                    walletType: P.string,
                    selectedAddress: P.string,
                },
                ({ selectedAddress }) => truncateAddress(selectedAddress, 6),
            )
            .otherwise(() => '');
    }, [walletState]);

    const handleSwitchNetworkClick = async () => {
        await switchNetwork(getNetworkByToken(appConfig.token).chainId);
    };

    const handleConnectClick = async () => {
        setModal('connect');
    };

    return (
        <div className={props.className}>
            {!hasConnected && (
                <Button
                    name="Connect Wallet"
                    id="connect-wallet-navbar"
                    onClick={handleConnectClick}
                    className="h-full"
                >
                    Connect Wallet
                </Button>
            )}
            {hasConnected && !hasValidNetwork && (
                <Button
                    name="Switch Network"
                    id="wrong-network-btn"
                    onClick={handleSwitchNetworkClick}
                    className="h-full"
                    variant="outline-tertiary"
                >
                    Switch Network
                </Button>
            )}
            {hasConnected && hasValidNetwork && (
                <Button
                    suffix={
                        <Image asset={getAssetByWallet(walletState.walletType!)!} isIcon={true} />
                    }
                    variant="secondary"
                    onClick={handleConnectClick}
                    name="Connect wallet"
                    className="h-full"
                >
                    <div className="w-28 overflow-clip">{walletInfoText}</div>
                </Button>
            )}
        </div>
    );
};
