import { Environment } from '@/constants/types';

const stringToBoolean = (boolString: string | undefined): boolean => {
    switch (boolString?.toLowerCase().trim()) {
        case 'true':
        case '1':
            return true;
        default:
            return false;
    }
};

export const ENV_APP_ENV = process.env.APP_ENV || Environment.testnet;

export const MAINNET = ENV_APP_ENV === Environment.mainnet;

export const ENV_WEB_URL = process.env.WEB_URL;

export const ENV_APP_URL = process.env.APP_URL;

export const ENV_TESTNET_APP_URL = process.env.TESTNET_APP_URL;

export const ENV_DOCUMENTATION_APP_URL = process.env.DOCUMENTATION_APP_URL;

export const ENV_WEB_DOMAIN = process.env.WEB_DOMAIN;

export const ENV_NODE_ENV = process.env.NODE_ENV;

export const ENV_UNSTOPPABLE_DOMAINS_ID =
    process.env.NEXT_PUBLIC_UNSTOPPABLE_DOMAINS_ID || '2da61c6c-804a-4c8a-babe-ba393cb79f0e';
export const ENV_UNSTOPPABLE_DOMAINS_REDIRECT =
    process.env.NEXT_PUBLIC_UNSTOPPABLE_DOMAINS_REDIRECT || 'http://localhost:3333';
