import { toast } from 'react-toastify';

export interface ProviderRpcError extends Error {
    code: number;
    message: string;
    data?: unknown;
}

export interface ProviderRpcMessageData {
    value: {
        code: string;
        message: string;
    };
}

export enum ErrorContext {
    USER_STAKE = 'USER_STAKE',
    USER_WITHDRAW = 'USER_WITHDRAW',
    USER_INSTANT_WITHDRAW = 'USER_INSTANT_WITHDRAW',
    USER_CLAIM = 'USER_CLAIM',
    USER_ALLOW = 'USER_ALLOW',
    USER_FAUCET = 'USER_FAUCET',
    USER_SWAP_TOKEN_V2 = 'USER_SWAP_TOKEN_V2',
    WALLET_METAMASK = 'WALLET_METAMASK',
    WALLET_COINBASE = 'WALLET_COINBASE',
    WALLET_UNSTOPPABLE_DOMAINS = 'WALLET_UNSTOPPABLE_DOMAINS',
    WALLET_NETWORK_SWITCH = 'WALLET_NETWORK_SWITCH',
    WALLET_NETWORK_ADD = 'WALLET_NETWORK_ADD',
    WALLET_TOKEN_ADD = 'WALLET_TOKEN_ADD',
    OTHER = 'OTHER',
}

const ERROR_MESSAGES: Record<string, string> = {
    '4001': 'Please approve the transaction to continue.',
    '-32603': 'Transaction gas underpriced. Please increase gas price.',
};

const ERROR_MESSAGES_CONTEXT: Record<string, Record<string, string>> = {
    USER_FAUCET: {
        '-32000': 'Insufficient funds. 0.2 ETH is needed for gas to use the faucet.',
    },
};

function titleCase(_str: string) {
    const str = _str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
}

export const errorMessage = (error: Error, isSilent: boolean = false) => {
    return handleError(ErrorContext.OTHER, isSilent)(error);
};

export const handleError =
    (context: ErrorContext, isSilent = false) =>
    (_error: Error) => {
        try {
            // @ts-expect-error: The error thrown by the provider has some custom objects attached with metadata
            let error = 'error' in _error ? _error.error : _error;
            // @ts-expect-error
            error = 'data' in _error ? _error.data : error;
            const message =
                'reason' in error
                    ? error.reason
                    : error.message.includes('[ethjs-query]')
                    ? handleProviderRpcError(error)
                    : error.message;
            // @ts-expect-error
            const code = 'code' in error ? error.code : _error.code;
            const errorMessageContext =
                context in ERROR_MESSAGES_CONTEXT ? ERROR_MESSAGES_CONTEXT[context][code] : null;

            const hasErrorMessage = code in ERROR_MESSAGES;

            let toastMessage = errorMessageContext
                ? errorMessageContext
                : hasErrorMessage
                ? ERROR_MESSAGES[code]
                : titleCase(message);

            if (!isSilent) {
                toast.error(toastMessage, { theme: 'dark' });
            }
        } catch {}
        return null;
    };

const handleProviderRpcError = (error: ProviderRpcError) => {
    var formattedErrorString = error.message.substring(
        error.message.indexOf("'") + 1,
        error.message.lastIndexOf("'"),
    );
    const messageData = JSON.parse(formattedErrorString) as ProviderRpcMessageData;
    return messageData.value.message.toLocaleUpperCase();
};
