import { TOKEN_CONFIG } from '@/constants/tokens';
import { Contract, CONTRACT_CONTEXT_V2, ProviderOrSigner, Token } from '@/constants/types';
import { getNetworkByToken } from '@/utils/utils';
import { ethers } from 'ethers';

type ContractUtilsOptions<T extends Token> = {
    token: T;
    rootProviderOrSigner?: ProviderOrSigner;
};

export const contractUtils = <T extends Token>({
    token,
    rootProviderOrSigner,
}: ContractUtilsOptions<T>) => {
    const network = getNetworkByToken(token)._id;
    const getAddress = (contractName: Contract) =>
        TOKEN_CONFIG[token].address[network]![contractName]!;
    const getAbi = async (contractName: Contract) => {
        try {
            return (await TOKEN_CONFIG[token].abi[network]![contractName]!()).default;
        } catch {
            return null;
        }
    };
    const getContract = async <C extends Contract>(
        contractName: C,
        providerOrSigner?: ProviderOrSigner,
        address?: string,
    ) => {
        try {
            return new ethers.Contract(
                address || getAddress(contractName),
                (await getAbi(contractName))!,
                providerOrSigner || rootProviderOrSigner,
            ) as unknown as CONTRACT_CONTEXT_V2[C];
        } catch {
            return null;
        }
    };

    return {
        getAddress,
        getAbi,
        getContract,
    };
};
