import { TranslationQuery } from 'next-translate';
import useTranslationDefault from 'next-translate/useTranslation';
import defaultLocale from '../../locales/en/main.json';

/**
 * Got this from SO:
 * @see https://stackoverflow.com/a/47058976/2713655
 */
type PathsToStringProps<T> = T extends string
    ? []
    : {
          [K in Extract<keyof T, string>]: [K, ...PathsToStringProps<T[K]>];
      }[Extract<keyof T, string>];

type Join<T extends string[], D extends string> = T extends []
    ? never
    : T extends [infer F]
    ? F
    : T extends [infer F, ...infer R]
    ? F extends string
        ? `${F}${D}${Join<Extract<R, string[]>, D>}`
        : never
    : string;

type DottedLanguageObjectStringPaths = Join<PathsToStringProps<typeof defaultLocale>, '.'>;

const getNestedString = (object: any, key: string): string => {
    key = key.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    key = key.replace(/^\./, ''); // strip a leading dot
    var path = key.split('.');
    //Iterate through the nested obj with path
    for (var i = 0, n = path.length; i < n; ++i) {
        var partialKey = path[i];
        if (partialKey in object) {
            object = object[partialKey];
        } else {
            return '';
        }
    }
    return object;
};

export const useTranslation = () => {
    const { t, ...rest } = useTranslationDefault();

    const typedT = (
        key: DottedLanguageObjectStringPaths,
        query?: TranslationQuery | null,
        options?: {
            returnObjects?: boolean;
            fallback?: string | string[];
            default?: string;
            ns?: string;
        },
    ) => {
        let translation = t(key, query, options);
        if (key == translation) return getNestedString(defaultLocale, key);
        return translation;
    };

    return {
        t: typedT,
        ...rest,
    };
};
