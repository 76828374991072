import { Maybe } from '@/utils/common';
import { getNetworkByToken } from '@/utils/utils';
import { atom, selector } from 'recoil';
import { appConfigAtom } from './app';

export type WalletType = 'metamask' | 'wallet_connect' | 'coinbase' | 'unstoppable_domains';

type UnstoppableDomainsUser = {
    sub: string;
    wallet_address?: string;
};
export interface WalletAtom {
    walletType: Maybe<WalletType>;
    walletLoading: Maybe<WalletType>;
    selectedAddress?: Maybe<string>;
    chainId?: Maybe<number>;
    targetChainId?: number;
    unstoppableDomains: Maybe<UnstoppableDomainsUser>;
}

export const walletAtom = atom<WalletAtom>({
    key: '_wallet',
    default: {
        walletType: null,
        walletLoading: null,
        selectedAddress: null,
        chainId: 0,
        targetChainId: 1,
        unstoppableDomains: null,
    },
});

export const walletIsConnected = selector({
    key: '_wallet.isConnected',
    get: ({ get }) => {
        const state = get(walletAtom);
        return state.selectedAddress !== null;
    },
});

export const walletIsValidNetwork = selector({
    key: '_wallet.isValidNetwork',
    get: ({ get }) => {
        const appConfig = get(appConfigAtom);
        const walletState = get(walletAtom);
        const validNetwork = getNetworkByToken(appConfig.token).chainId;
        return validNetwork == walletState.chainId;
    },
});

/**
 * Flag if wallet is available for transactions.
 */
export const walletIsAvailable = selector({
    key: '_wallet.isAvailable',
    get: ({ get }) => {
        const state = get(walletAtom);
        const isConnected = get(walletIsConnected);
        return isConnected;
    },
});
