import { useRecoilValue } from 'recoil';

import { Button } from '@/components/atom/Button';
import { MAINNET } from '@/constants/env';
import { EXTERNAL_URLS } from '@/constants/urls';
import { useMixpanel } from '@/hooks/mixpanel';
import { useTranslation } from '@/hooks/translate';
import { appConfigAtom, userTransactionsAtom } from '@/state/app';
import { getNetworkByToken } from '@/utils/utils';
import { FC } from 'react';
import { Anchor } from './atom/Anchor';
import { Loader } from './atom/Loader';
import { NetworkIndicator } from './molecule/NetworkIndicator';
import { WalletInfoBox } from './organism/WalletInfoBox';

export interface AppNavProps {
    isFromFtm: boolean;
}

export const AppNav: FC<AppNavProps> = (props) => {
    const userTransactions = useRecoilValue(userTransactionsAtom);
    const appConfig = useRecoilValue(appConfigAtom);
    const { t } = useTranslation();
    const { track } = useMixpanel();

    const AppLink = (className: string) =>
        MAINNET ? (
            <Anchor
                className={className}
                caption={t('navbar.items.testnet')}
                href={EXTERNAL_URLS.testnet}
                isExternal={true}
                onClick={() => track('NAV-ELEMENTS 1')}
            />
        ) : (
            <Anchor
                className={className}
                caption={t('navbar.items.mainnet')}
                href={EXTERNAL_URLS.app}
                isExternal={true}
                onClick={() => track('NAV-ELEMENTS 1')}
            />
        );

    return (
        <div className="flex items-center gap-4 ml-auto">
            {AppLink('nav__item--flat lg:inline-block hidden')}
            {!MAINNET && (
                <Anchor
                    className="hidden nav__item--flat lg:inline-block"
                    caption={t('navbar.items.faucet')}
                    href={props.isFromFtm ? EXTERNAL_URLS.faucet.ftm : '/faucet'}
                    isNextLink={props.isFromFtm ? false : true}
                    isExternal={props.isFromFtm ? true : false}
                    id="faucet-btn"
                    onClick={() => track('NAV-ELEMENTS 2')}
                />
            )}
            <Anchor
                className="hidden nav__item--flat lg:inline-block"
                caption={t('navbar.items.missionHub')}
                href="/mission-hub"
                id="mission-hub-btn"
                onClick={() => track('NAV-ELEMENTS 3')}
            />

            {['MATIC', 'MATIC_POLYGON'].includes(appConfig.token) && (
                <NetworkIndicator
                    network={
                        MAINNET
                            ? getNetworkByToken(appConfig.token).family.name
                            : getNetworkByToken(appConfig.token).name
                    }
                    onClick={() => {
                        track('NAV-BUTTON 1');
                    }}
                />
            )}
            {userTransactions.length > 0 ? (
                <Button suffix={<Loader color="#fff" />} className="pointer-events-none">
                    <span>{userTransactions.length} Pending</span>
                </Button>
            ) : (
                <WalletInfoBox className="h-10" />
            )}
        </div>
    );
};
