import { MAINNET } from '@/constants/env';
import { TOKEN_CONFIG } from '@/constants/tokens';
import { Network } from '@/constants/types';
import { useWallet } from '@/hooks/wallet';
import { appConfigAtom } from '@/state/app';
import { walletAtom, walletIsConnected } from '@/state/wallet';
import { getAssetByNetwork, getNetworkByChainId, isValidChainId } from '@/utils/utils';
import { Circle } from 'iconoir-react';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { SelectDropdown } from './SelectDropdown';

type NetworkIndicatorProps = {
    network: string;
    className?: string;
    onClick?: () => void;
};
type NetworkOption = {
    id: string;
    caption: string;
    icon: string;
    slug: string;
    network: string;
    chainId: number;
};
export const NetworkIndicator = ({ network, onClick }: NetworkIndicatorProps) => {
    const { switchNetwork } = useWallet();
    const walletState = useRecoilValue(walletAtom);
    const appState = useRecoilValue(appConfigAtom);

    const router = useRouter();
    const isWalletConnected = useRecoilValue(walletIsConnected);
    const isDropdownEnabled = router.asPath.includes('/stake');

    const walletNetwork = useMemo(() => {
        if (walletState.chainId && isValidChainId(walletState.chainId)) {
            return getNetworkByChainId(walletState.chainId).name;
        } else {
            return network;
        }
    }, [walletState.chainId]);

    const dropdownOptions = useMemo(() => {
        const tokenConfig = TOKEN_CONFIG[appState.token];
        const cardsMap = Object.entries(TOKEN_CONFIG).reduce((acc, [t, config]) => {
            if (MAINNET && !config.vocab.inMainnet) return acc;
            const vocab = config.vocab;
            const parentToken = vocab.parentId;
            const network = MAINNET ? config.mainnet : config.testnet;
            if (!(parentToken in acc)) {
                acc[parentToken] = {
                    networks: [],
                };
            }
            acc[parentToken].networks!.push({
                id: network._id,
                caption: MAINNET ? network.family.name : network.name,
                icon: network.family.icon,
                slug: vocab.slug,
                network: network._id,
                chainId: network.chainId,
            });
            return acc;
        }, {} as any);
        return cardsMap[tokenConfig.vocab.parentId].networks as NetworkOption[];
    }, [router.asPath, appState.token]);

    const ButtonIcon = (
        <NextImage
            src={getAssetByNetwork(walletNetwork as Network)}
            width={24}
            height={24}
            alt={walletNetwork}
            className="pointer-events-none"
        />
    );
    const NetworkOption = (option: NetworkOption) => {
        const isOnRightNetwork = walletNetwork.toLocaleLowerCase() == option.network;
        const icon = getAssetByNetwork(option.network as Network);
        return (
            <button
                aria-label={option.caption}
                onClick={onClick}
                className="flex items-center w-full gap-2 px-3 py-2 rounded-lg hover:bg-[#4d3964]"
            >
                <NextImage
                    alt={option.caption}
                    src={icon}
                    width={24}
                    height={24}
                    className="flex-shrink-0"
                />
                <span className="t__button--u1">{option.caption}</span>
                {isOnRightNetwork ? (
                    <Circle className="w-3 h-3 ml-auto fill-current text-green-60" fr="true" />
                ) : null}
            </button>
        );
    };

    const handleOptionClick = (slug: string, chainId: number) => {
        switchNetwork(chainId);
        router.push(`/stake/${slug}`);
    };

    return isWalletConnected && isDropdownEnabled ? (
        <div>
            <SelectDropdown
                title={walletNetwork}
                label="Select Network"
                prefix={ButtonIcon}
                isReadonly={false}
                hideTextOnMobile={true}
            >
                <div className="flex flex-col text-white">
                    {dropdownOptions.map((option, index) => (
                        <div
                            className="w-48 mt-1"
                            key={index}
                            onClick={() => handleOptionClick(option.slug, option.chainId)}
                        >
                            <NetworkOption {...option} />
                        </div>
                    ))}
                </div>
            </SelectDropdown>
        </div>
    ) : null;
};
