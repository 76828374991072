import { ethers } from 'ethers';
import { instance } from './instance-api';

export interface Activity {
    _id: string;
    points: number;
}

export interface StandingResponse {
    _id: string;
    total_points: number;
    diamonds: number;
    diamonds_current_week: number;
    ranking: number;
    activities: Record<string, Activity>;
}

export const defaultStanding = {
    _id: '',
    total_points: 0,
    diamonds: 0,
    diamonds_current_week: 0,
    ranking: 0,
    activities: {} as Record<string, Activity>,
};

export const getStanding = async (address: string) => {
    const response = await instance.post<StandingResponse>('/standing', {
        address: ethers.utils.getAddress(address),
    });
    return response.data;
};
