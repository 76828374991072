import { appConfigAtom } from '@/state/app';
import { getVocabBySlug } from '@/utils/utils';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

export const useRouterGuard = () => {
    const router = useRouter();
    const setAppConfig = useSetRecoilState(appConfigAtom);

    const setConfig = (url: string) => {
        const isStakePage = url.includes('/stake/');
        if (isStakePage) {
            const slug = url.match(/\/stake\/(\w+)/)![1];
            const vocab = getVocabBySlug(slug as string);
            if (vocab) {
                setAppConfig({
                    token: vocab._id,
                });
            }
        }
    };
    const setMissionHubTheme = (url: string) => {
        const isMissionHub = url.includes('/mission-hub');
        if (isMissionHub) {
            document.body.classList.add('bg-plain');
        } else {
            document.body.classList.remove('bg-plain');
        }
    };

    const handleRouteChange = (url: string) => {
        // setMissionHubTheme(url);
        setConfig(url);
    };

    useEffect(() => {
        setConfig(router.asPath);
        router.events.on('routeChangeStart', handleRouteChange);
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router.events, router.asPath]);
};
