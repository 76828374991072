import { appUserAckAtom } from '@/state/app';
import { walletAtom } from '@/state/wallet';
import { isNull } from '@/utils/common';
import { getUserAck } from '@/utils/http/get-user-ack';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MAINNET } from '@/constants/env';

export const useUserAck = () => {
    const walletState = useRecoilValue(walletAtom);
    const setUserAck = useSetRecoilState(appUserAckAtom);

    useEffect(() => {
        if (!MAINNET || isNull(walletState.selectedAddress)) return;

        (async () => {
            const hasAck = await getUserAck(walletState.selectedAddress!);
            if (hasAck) {
                setUserAck({
                    address: walletState.selectedAddress!,
                    show: false,
                    loaded: true,
                });
            } else {
                setUserAck({
                    address: '',
                    show: false,
                    loaded: true,
                });
            }
        })();
    }, [walletState.selectedAddress]);
};
