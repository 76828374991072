import { ENV_APP_ENV } from '@/constants/env';
import { NETWORK_CONFIG, RPC_URLS, SWAP_TOKEN_LIST, TOKEN_CONFIG } from '@/constants/tokens';
import { Environment, ERC20Token, Network, ProviderOrSigner, Token } from '@/constants/types';
import { WALLET_LIST } from '@/constants/wallets';
import { WalletType } from '@/state/wallet';
import { ContractContext as ClayFTMContext } from '@/utils/contracts/abi/ClayFTM';
import { ContractContext as ClayGraphContext } from '@/utils/contracts/abi/ClayGraph';
import { ContractContext as ClayMaticContext } from '@/utils/contracts/abi/ClayMatic';
import { contractUtils } from '@/utils/contracts/utils';
import { ethers } from 'ethers';

export const getNetworkByToken = (token: Token) => {
    switch (ENV_APP_ENV) {
        case Environment.localhost:
            return NETWORK_CONFIG.localhost;
        case Environment.testnet:
            return TOKEN_CONFIG[token].testnet;
        default:
            return TOKEN_CONFIG[token].mainnet;
    }
};
export const getVocabByToken = (token: Token) => TOKEN_CONFIG[token].vocab;
export const getCgIdByToken = (token: Token) => TOKEN_CONFIG[token].cgId;
export const getVocabBySlug = (slug: string) => {
    const vocab = Object.values(TOKEN_CONFIG).find((v) => v.vocab.slug === slug)?.vocab;
    return vocab!;
};
export const getAssetBySymbol = (symbol: string) => {
    const config = Object.values(TOKEN_CONFIG).find((v) => symbol in v.assets);
    return config === undefined ? null : config.assets[symbol];
};
export const getAssetBySymbolForIndex = (symbol: string) => {
    const config = Object.values(TOKEN_CONFIG).find((v) => symbol in v.assets);
    return config === undefined ? null : config.assets[symbol] + '-main';
};
export const getNetworkByChainId = (chainId: number) =>
    Object.values(NETWORK_CONFIG).find((network) => network.chainId === chainId)!;

export const isValidChainId = (chainId: number) =>
    Object.values(NETWORK_CONFIG).find((network) => network.chainId === chainId) !== undefined;

export const isFantom = (token: Token) => token === 'FTM';
export const getBackendProvider = async (network: Network) => {
    const urls = RPC_URLS[network];
    for (const url of urls) {
        try {
            const provider = new ethers.providers.StaticJsonRpcProvider(url);
            await provider._ready();
            return provider;
        } catch (e) {
            console.warn(`FAILED: ${url} - TRYING NEXT RPC`);
        }
    }

    // Returns default
    return ethers.getDefaultProvider(network);
};

export const getContractUtils = (token: Token, rootProviderOrSigner?: ProviderOrSigner) =>
    contractUtils({
        token,
        rootProviderOrSigner,
    });

export const getAssetByWallet = (walletType: WalletType) =>
    WALLET_LIST.find((w) => w._id === walletType)?.asset ?? null;

export const getAssetByNetwork = (network: Network) => {
    switch (network.toLowerCase()) {
        case 'polygon':
            return '/images/icon__matic.svg';
        case 'mumbai':
            return '/images/icon__matic.svg';
        case 'fantom':
            return '/images/icon__ftm.svg';
        case 'fantom_testnet':
            return '/images/icon__ftm.svg';
        case 'fantom testnet':
            return '/images/icon__ftm.svg';
        default:
            return '/images/icon__ethereum.svg';
    }
};

export const getApproxWithdrawTimeInEpochs = (token: Token, network: Network) => {
    let noOfCheckpoints = 0;
    if (token == 'MATIC' || token === 'MATIC_POLYGON') {
        noOfCheckpoints = 80;
    } else if (token === 'GRT') {
        if (network == 'rinkeby') {
            noOfCheckpoints = 6;
        } else if (network === 'mainnet') {
            noOfCheckpoints = 28;
        }
    } else if (token == 'FTM') {
        noOfCheckpoints = 3;
    }
    return `${noOfCheckpoints} epochs`;
};

export const getSwapToken = (token: ERC20Token) => SWAP_TOKEN_LIST.find((s) => s.token === token)!;

export const getTotalStakeOfToken = async (
    contract: ClayFTMContext | ClayGraphContext | ClayMaticContext | null,
    token: Token,
) => {
    //The types are broken because the FTM main contract is missing some things or are different
    const funds = await contract!.funds();
    //@ts-ignore-check
    let totalStake = funds ? funds.currentDeposit : 0;
    if (token === 'FTM') {
        const exchangeRate = await (await contract!).getExchangeRate();
        //@ts-ignore-check
        totalStake = exchangeRate[3];
    }
    return totalStake;
};
