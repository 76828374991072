
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { PageLoader } from '@/components/atom/PageLoader';
import { Layout } from '@/components/Layout';
import { useRouterProgress } from '@/hooks/router-progress';
import '@/styles/globals.css';
import '@/utils/sentry';
import '@public/fonts/style.css';
import { Web3ReactProvider } from '@web3-react/core';
import { providers } from 'ethers';
import { AnimatePresence } from 'framer-motion';
import type { AppProps } from 'next/app';
import 'react-tippy/dist/tippy.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffectOnce } from 'react-use';
import { RecoilRoot } from 'recoil';

function getLibrary(provider: any): providers.Web3Provider {
    const library = new providers.Web3Provider(provider, 'any');
    library.pollingInterval = 12000;
    return library;
}

function MyApp({ Component, pageProps }: AppProps) {
    const { state } = useRouterProgress();
    useEffectOnce(() => {
        document.querySelector('#loader')?.remove();
    });

    return (
        <>
            <PageLoader isRouteChanging={state.isRouteChanging} key={state.loadingKey} />
            <RecoilRoot>
                <Web3ReactProvider getLibrary={getLibrary}>
                    <Layout>
                        <AnimatePresence exitBeforeEnter initial={false}>
                            <Component {...pageProps} />
                        </AnimatePresence>
                    </Layout>
                    <ToastContainer
                        position={toast.POSITION.TOP_CENTER}
                        pauseOnHover={false}
                        pauseOnFocusLoss={false}
                        draggable={false}
                        autoClose={10000}
                        newestOnTop={true}
                    />
                </Web3ReactProvider>
            </RecoilRoot>
        </>
    );
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  