import { Transaction } from '@/components/transactions/TransactionAction';
import { ERC20Token, Token } from '@/constants/types';
import { isNull, Maybe, Theme } from '@/utils/common';
import { defaultStanding } from '@/utils/http/get-standing';
import { BigNumber } from 'ethers';
import { atom, selector } from 'recoil';
import { walletAtom } from './wallet';
import { RateLimit } from '@/utils/http/twitter';

interface AppConfigAtom {
    token: Token;
}

interface AppHelpCenterAtom {
    isOpen: boolean;
    lastSearches: string[];
}

export const appHelpCenterAtom = atom<AppHelpCenterAtom>({
    key: '_app.helpCenter',
    default: {
        isOpen: false,
        lastSearches: [],
    },
});

export type ModalValue = 'connect' | 'invites' | 'point-system' | 'wallet-error' | 'swap';
export const appModalManager = atom<Maybe<ModalValue>>({
    key: '_app.modalManager',
    default: null,
});

export const appConfigAtom = atom<AppConfigAtom>({
    key: '_app.config',
    default: {
        token: 'MATIC',
    },
});

export const appUserAckAtom = atom({
    key: '_app.userAck',
    default: {
        address: '',
        loaded: false,
        show: false,
    },
});

export const appUserReferralAtom = atom({
    key: '_app.userReferral',
    default: {
        visible: false,
        loaded: false,
    },
});

export enum Events {
    stakeEvent = 'stakeEvent',
    unstakeEvent = 'unstakeEvent',
    flashExitEvent = 'flashExitEvent',
    claimEvent = 'claimEvent',
    allowEvent = 'allowEvent',
    faucetEvent = 'faucetEvent',
    transactionEvent = 'transactionEvent',
    updateValuesEvent = 'updateValuesEvent',
    refreshPoints = 'refreshPoints',
    csTokenV2SwapEvent = 'csTokenV2SwapEvent',
    csTokenV2AllowEvent = 'csTokenV2AllowEvent',
}

export const appEventsAtom = atom<Record<Events, number>>({
    key: '_app.events',
    default: {
        stakeEvent: 0,
        unstakeEvent: 0,
        flashExitEvent: 0,
        claimEvent: 0,
        allowEvent: 0,
        faucetEvent: 0,
        transactionEvent: 0,
        updateValuesEvent: 0,
        refreshPoints: 0,
        csTokenV2AllowEvent: 0,
        csTokenV2SwapEvent: 0,
    },
});

export const appPointsAtom = atom({
    key: '_app.points',
    default: {
        isLoading: true,
        standingData: defaultStanding,
        lastUpdate: null as Maybe<Date>,
        twitterLimit: {} as RateLimit,
    },
});

export const appSignatureAtom = atom({
    key: '_app.signature',
    default: {
        address: null,
        signature: null,
    },
});

interface AppDataAtom {
    currentDeposits: BigNumber;
    instantWithdrawFee: BigNumber;
    withdrawFee: BigNumber;
    depositFee: BigNumber;
    rewardFee: BigNumber;
    stakingFee: BigNumber;
    earlyClaimFee: BigNumber;
    exchangeRate: BigNumber;
    instantWithdrawalLimit: BigNumber;
    withdrawLimit: BigNumber;
    tokenBalance: BigNumber;
    tokenAllowance: BigNumber;
    csTokenBalance: BigNumber;
    csTokenAllowance: BigNumber;
    csTokenV1Balance: BigNumber;
    csTokenV1Allowance: BigNumber;
    csTokenClayExchangeAllowance: BigNumber;
    claimableAmount: BigNumber;
    defaultLiquidity: BigNumber;
    swapExchangeRate: BigNumber;
    swapToken: null | ERC20Token;
    isDataLoading: boolean;
    isBalanceLoading: boolean;
    isClaimsLoading: boolean;
}

export const appDataAtom = atom<AppDataAtom>({
    key: '_app.data',
    default: {
        currentDeposits: BigNumber.from(0),
        instantWithdrawFee: BigNumber.from(0),
        withdrawFee: BigNumber.from(0),
        depositFee: BigNumber.from(0),
        rewardFee: BigNumber.from(0),
        stakingFee: BigNumber.from(0),
        earlyClaimFee: BigNumber.from(0),
        exchangeRate: BigNumber.from(0),
        instantWithdrawalLimit: BigNumber.from(0),
        withdrawLimit: BigNumber.from(0),
        tokenBalance: BigNumber.from(0),
        tokenAllowance: BigNumber.from(0),
        csTokenBalance: BigNumber.from(0),
        csTokenAllowance: BigNumber.from(0),
        csTokenV1Balance: BigNumber.from(0),
        csTokenV1Allowance: BigNumber.from(0),
        csTokenClayExchangeAllowance: BigNumber.from(0),
        claimableAmount: BigNumber.from(0),
        defaultLiquidity: BigNumber.from(0),
        swapExchangeRate: BigNumber.from(0),
        swapToken: null,
        isDataLoading: false,
        isBalanceLoading: false,
        isClaimsLoading: false,
    },
});

export const swapEnabledAtom = selector({
    key: '_app.swapEnabled',
    get: ({ get }) => {
        const state = get(appDataAtom);
        const config = get(appConfigAtom);
        return state.swapToken && config.token === 'MATIC_POLYGON' ? state.swapToken : null;
    },
});

export const appThemeAtom = atom<Theme>({
    key: '_app.theme',
    default: 'light',
});

export const appTransactionsAtom = atom<Transaction[]>({
    key: '_app.transactions',
    default: [],
});

export const userTransactionsAtom = selector({
    key: '_app.userTransactions',
    get: ({ get }) => {
        const metamask = get(walletAtom);
        const list = get(appTransactionsAtom);
        if (isNull(metamask.selectedAddress)) return list;
        return list.filter(
            (l) =>
                l.address.toLocaleLowerCase() === metamask.selectedAddress?.toLocaleLowerCase() &&
                l.chainId === metamask.chainId,
        );
    },
});
