import { AppLocales, LOCALES_LABELS } from '@/constants/i18n';
import { useTranslation } from '@/hooks/translate';
import { useRouter } from 'next/router';

export const useLanguage = () => {
    const { lang } = useTranslation();
    const router = useRouter();

    const selectedLanguage = LOCALES_LABELS[lang as AppLocales];

    const localesList = Object.entries(LOCALES_LABELS).map(([locale, label]) => ({
        locale,
        label,
    }));

    const handleLanguageChange = (locale: string) =>
        router.push(router.asPath, undefined, { locale });

    return {
        localesList,
        selectedLanguage,
        currentLocale: lang,
        handleLanguageChange,
    };
};
