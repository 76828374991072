import { Size } from '@/utils/common';
import { CSSProperties } from 'react';

type LoaderProps = {
    size?: Size;
    className?: string;
    color?: string;
};
export const Loader = ({ size = 'small', className, color = '#EB00F4' }: LoaderProps) => {
    const sizeProps = {
        small: { length: 18, strokeWidth: 2 },
        medium: { length: 24, strokeWidth: 3 },
        large: { length: 48, strokeWidth: 6 },
    };
    const { length } = sizeProps[size!];

    const styles: Record<string, CSSProperties> = {
        spin_loader: {
            width: `${length}px`,
            height: `${length}px`,
            borderRadius: '50%',
            display: 'inline-block',
            borderTop: `3px solid ${color}`,
            borderRight: '3px solid transparent',
            boxSizing: 'border-box',
            animation: 'spin 750ms linear infinite',
        },
    };
    return (
        <>
            <span style={styles.spin_loader} className={className}></span>
        </>
    );
};
Loader.defaultProps = {
    size: 'medium',
};
