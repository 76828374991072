import { ENV_NODE_ENV } from '@/constants/env';
import { BigNumber, ethers } from 'ethers';
import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from 'react';

const USD_PRECISION = 2;
const USD_MULTIPLIER = 10 ** USD_PRECISION;

export type Maybe<T> = T | null;
export type Size = 'small' | 'medium' | 'large';
export type Theme = 'light' | 'dark';

export const hexToInt = (hex: string) => parseInt(hex, 16);
export const intToHex = (num: number) => '0x' + num.toString(16);

export const isNull = (val: unknown): val is null => val === null;

export const isFactory =
    <P extends PropsWithChildren<{}>>(props: P) =>
    <K extends keyof P>(prop: K) =>
    <V extends P[K]>(value: V) =>
        props[prop] === value;

export const isValidAddress = (address: string) => {
    const matchRegex = /0x[\w]{40}/i;
    return matchRegex.test(address);
};

export const isAllowedNumber = (value: string): boolean => {
    if (+value >= Number.MAX_SAFE_INTEGER || +value < 0) {
        return false;
    }
    return true;
};

export const isValidNumberKeypress = (value: string) => {
    return RegExp('[0-9]').test(value) || value == '.';
};

export const isDev = () =>
    global.location?.hostname === 'localhost' || ENV_NODE_ENV === 'development';

export const formatEther = (unit: Maybe<BigNumber>) =>
    unit !== null ? ethers.utils.formatUnits(unit, 'ether') : '-';

export const formatNumber = (unit: number, precision = 2, round = false) => {
    if (unit > 10 && round) {
        return Number.isSafeInteger(unit) ? unit : unit.toFixed(0);
    } else {
        return Number.isSafeInteger(unit) ? unit : unit.toFixed(precision);
    }
};

export const formatPercent = (num: BigNumber) => (num.gt(0) ? `${num.toNumber() / 100}%` : '0%');

export const formatNumberPercent = (num: number, decimals = 2) =>
    num > 0 ? `${num.toFixed(decimals)}%` : '0%';

export const formatNumberHuman = (
    num: number,
    notation: Intl.NumberFormatOptions['notation'] = 'compact',
) => {
    const formatter = new Intl.NumberFormat('en-US', { notation });
    return formatter.format(num);
};

export const formatNumberEther = (amount: Maybe<number>, symbol: string) =>
    !isNull(amount) ? `${formatNumberHuman(amount)} ${symbol}` : '-';

export const formatNumberEtherWithoutSymbol = (amount: Maybe<number>) =>
    !isNull(amount) ? `${formatNumberHuman(amount)}` : '-';

export const formatEmojiFactory =
    (minValue: number, emoji = '🔥') =>
    (value: number) => {
        if (value === undefined) return '';
        const prefix = value >= minValue ? emoji : '';
        return `${prefix} ${value.toLocaleString()}`;
    };

export const trimDecimals = (unit: string) => {
    const decIndex = unit.indexOf('.');
    if (decIndex === -1) return unit;
    const decDigits = unit.length - decIndex;
    if (decDigits <= 18) return unit;
    const offset = decDigits - 19;
    return unit.substring(0, unit.length - offset);
};

export const parseEther = (unit: number | string) => {
    const unitString = typeof unit === 'number' ? unit.toString() : unit;
    return ethers.utils.parseEther(trimDecimals(unitString));
};

export const parseAddress = (address: string) => ethers.utils.getAddress(address);
export const parseBigNumberDate = (bn: BigNumber) =>
    new Date(BigNumber.from(bn).mul(1000).toNumber());

export const getFeeAmount = (val: BigNumber, fees: BigNumber) =>
    fees.gt(0) ? fees.mul(val).div(10000) : BigNumber.from(0);

export const getXrValue = (val: Maybe<BigNumber>, exchangeRate: BigNumber, isInverse = false) => {
    if (isNull(val) || val.lte(0)) return BigNumber.from(0);
    const rate = exchangeRate && exchangeRate.gt(0) ? exchangeRate : ethers.constants.WeiPerEther;
    const MULTIPLIER = isInverse ? rate : ethers.constants.WeiPerEther;
    const DIVIDER = isInverse ? ethers.constants.WeiPerEther : rate;
    return val.mul(MULTIPLIER).div(DIVIDER);
};

export const getExchangeValue = (
    val: BigNumber,
    fees: BigNumber,
    exchangeRate: BigNumber,
    isInverse = false,
) => {
    if (val.lte(0)) return BigNumber.from(0);
    const exchangeVal = getXrValue(val, exchangeRate, isInverse);
    const feeAmount = getFeeAmount(exchangeVal, fees);
    return exchangeVal.sub(feeAmount);
};

export const numberToFixed = (
    bn: Maybe<BigNumber>,
    precision = 2,
    isFiat = false,
): number | string => {
    if (isNull(bn)) return '-';
    let valueEther = Number(formatEther(bn));
    if (valueEther < 0.001) {
        return valueEther.toFixed(precision);
    }
    const formatted = valueEther.toFixed(precision);
    return isFiat ? Number(formatted).toLocaleString() : formatted;
};

type BigNumberJSON = { type: 'BigNumber'; hex: string };
type StringRecord<T> = Record<string, T>;

export type SerializedData<T> = {
    [K in keyof T]: T[K] extends Maybe<infer X> ? Maybe<X> : BigNumberJSON;
};

export type DeserializedData<T> = {
    [K in keyof T]: T[K] extends number ? number : BigNumber;
};

export const serializeData = <D extends StringRecord<BigNumber | number>>(data: D) => {
    return Object.keys(data).reduce((acc, key) => {
        const value = data[key];
        acc[key as keyof SerializedData<D>] = BigNumber.isBigNumber(value) ? value.toJSON() : value;
        return acc;
    }, {} as SerializedData<D>);
};

export const deserializeData = <D extends StringRecord<BigNumber | number>>(
    data: SerializedData<D>,
) => {
    return Object.keys(data).reduce((acc, key) => {
        const value = data[key as keyof D];
        // @ts-expect-error: not sure how to fix this one. Works as intended.
        acc[key as keyof D] = value
            ? typeof value === 'number'
                ? Number(value)
                : BigNumber.from(value)
            : value;
        return acc;
    }, {} as D);
};

export const calculateTvl = (usdPrice: number, stakeAmount: BigNumber) =>
    BigNumber.from(Math.round(usdPrice * USD_MULTIPLIER))
        .mul(stakeAmount)
        .div(USD_MULTIPLIER);

export type DefaultProps<T = HTMLElement> = DetailedHTMLProps<HTMLAttributes<T>, T>;

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const truncateAddress = (
    address: string | null | undefined,
    startCharCount = 24,
    lastCharCount = 4,
) => {
    if (!address) return '';
    const parsedAddress = ethers.utils.getAddress(address);
    const endStartAt = parsedAddress.length - lastCharCount;
    return `${parsedAddress.slice(0, startCharCount)}...${parsedAddress.slice(endStartAt)}`;
};

export const areSameAddress = (addressOne: string, addressTwo: string) => {
    return parseAddress(addressOne) === parseAddress(addressTwo);
};

export const round = (number: number, precision: number) =>
    Math.round(number * 10 ** precision) / 10 ** precision;

export const random = (max: number) => Math.floor(Math.random() * max);

export function sortByKey(array: any[], key: string, isDescending = false) {
    return array.sort(function (a: { [x: string]: any }, b: { [x: string]: any }) {
        var x = isDescending ? a[key] : b[key];
        var y = isDescending ? b[key] : a[key];
        return x < y ? 1 : x > y ? -1 : 0;
    });
}
