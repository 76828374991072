import { LocalStorageKeys } from '@/constants/local-storage';
import { useMixpanel } from '@/hooks/mixpanel';
import { appThemeAtom } from '@/state/app';
import { Theme } from '@/utils/common';
import { useRouter } from 'next/router';
import { CSSProperties, useEffect, useState } from 'react';
import { useEvent, useLocalStorage } from 'react-use';
import { useRecoilState } from 'recoil';

const px = (n: number) => `${n}px`;

export const useTheme = () => {
    const { mixpanel } = useMixpanel();
    const [currentTheme, setTheme] = useRecoilState(appThemeAtom);
    const [cachedTheme, setCacheTheme] = useLocalStorage<Theme>(
        LocalStorageKeys.THEME,
        currentTheme,
    );

    const updateTheme = (value: Theme) => {
        document.querySelector('html')!.className = value;
        setTheme(value);
        setCacheTheme(value);
        mixpanel.people.set('App theme', value);
    };

    const toggleTheme = () => {
        const newTheme = currentTheme === 'light' ? 'dark' : 'light';
        updateTheme(newTheme);
    };

    useEffect(() => {
        cachedTheme && updateTheme(cachedTheme);
    }, [cachedTheme]);

    return {
        currentTheme,
        updateTheme,
        toggleTheme,
    };
};

const DEFAULT_STYLES = {
    left: '45%',
    top: '120px',
    width: '50vw',
    height: '50vh',
};
export const useAnchorBackground = () => {
    const { route } = useRouter();

    const [style, setStyle] = useState<CSSProperties>(DEFAULT_STYLES);

    const updateBackground = () => {
        const anchor = document.querySelector('[data-bg-anchor]');
        if (!anchor) {
            setStyle(DEFAULT_STYLES);
            return;
        }
        const coords = anchor.getBoundingClientRect();
        const newStyles: CSSProperties = {
            top: px(coords.top),
            left: px(coords.left),
            width: px(coords.width),
            height: px(coords.height),
        };
        setStyle(newStyles);
    };
    const hideBackground = () => {
        setStyle({
            display: 'none',
        });
    };
    const initBackground = () => {
        if (route === '/') {
            return hideBackground();
        } else {
            return updateBackground();
        }
    };

    useEffect(() => {
        return initBackground();
    }, [route]);
    useEvent('resize', initBackground);

    return { style };
};
