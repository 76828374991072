import { AppNav } from '@/components/AppNav';
import { MissionHubNav } from '@/components/mission-hub/MissionHubNav';
import { MAINNET } from '@/constants/env';
import { EXTERNAL_URLS } from '@/constants/urls';
import { useMixpanel } from '@/hooks/mixpanel';
import { useTranslation } from '@/hooks/translate';
import classNames from 'classnames';
import {
    Discord,
    Droplet,
    Flask,
    InfoEmpty,
    Language,
    LeaderboardStar,
    OpenBook,
    Planet,
    QuestionMarkCircle,
    Twitter,
} from 'iconoir-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useMedia } from 'react-use';
import { Image } from './atom/Image';
import { NavItemProps } from './atom/NavItem';
import { NavSubmenu } from './molecule/NavSubmenu';

export const Navbar = () => {
    const router = useRouter();
    const { track } = useMixpanel();

    const isMissionHub = useMemo(() => router.asPath.includes('mission-hub'), [router.asPath]);

    const { t } = useTranslation();

    const isMissionHubPage = router.asPath === '/mission-hub';
    const isDesktop = useMedia('(min-width: 1024px)', true);
    const isTestnet = !MAINNET;
    const isMainnet = MAINNET;
    const isFromFtm = router.asPath.includes('ftm');
    const baseIconStyles = {
        width: 18,
        height: 18,
        className: 'text-grey-80 dark:text-grey-20',
        fr: 'true',
        strokeWidth: 1.5,
    };
    const navItems: (NavItemProps | null)[] = [
        {
            label: isMainnet ? t('navbar.items.testnet') : t('navbar.items.mainnet'),
            href: isMainnet ? EXTERNAL_URLS.testnet : EXTERNAL_URLS.app,
            icon: isMainnet ? <Flask {...baseIconStyles} /> : <Planet {...baseIconStyles} />,
            isVisible: !isDesktop,
        },
        {
            label: t('navbar.items.faucet'),
            href: isFromFtm ? EXTERNAL_URLS.faucet.ftm : '/faucet',
            icon: <Droplet {...baseIconStyles} />,
            isVisible: isTestnet && !isDesktop,
            isExternal: isFromFtm ? true : false,
        },
        {
            label: t('navbar.items.missionHub'),
            icon: <LeaderboardStar {...baseIconStyles} />,
            isVisible: !isDesktop,
            href: '/mission-hub',
            isExternal: false,
            id: 'mission-hub-nav-btn',
            locale: 'en',
        },
        {
            label: t('navbar.items.faq'),
            icon: <QuestionMarkCircle {...baseIconStyles} />,
            href: EXTERNAL_URLS.faq,
            isVisible: isTestnet || (isMainnet && !isDesktop),
            id: 'faq-nav-btn',
        },
        {
            label: t('navbar.items.documentation'),
            icon: <OpenBook {...baseIconStyles} />,
            href: EXTERNAL_URLS.documentation,
        },
        {
            label: t('navbar.items.about'),
            icon: <InfoEmpty {...baseIconStyles} />,
            href: EXTERNAL_URLS.web,
        },
        !isMissionHubPage
            ? {
                  label: 'Language',
                  icon: <Language {...baseIconStyles} />,
                  hasNestedMenu: true,
              }
            : null,
        {
            label: 'Discord',
            icon: <Discord {...baseIconStyles} />,
            href: EXTERNAL_URLS.social.discord,
        },
        {
            label: 'Twitter',
            icon: <Twitter {...baseIconStyles} />,
            href: EXTERNAL_URLS.social.twitter,
        },
    ];

    return (
        <>
            <nav className="container mx-auto flex justify-between h-[4.5rem] items-center z-30 px-4 relative">
                <Link href="/" passHref>
                    <a className="pt-2" onClick={() => track('NAV-LOGO')}>
                        <Image
                            className={classNames('md:hidden', {
                                'inline-block': MAINNET,
                                hidden: !MAINNET,
                            })}
                            asset={'claystack-logo-mobile'}
                            width={21}
                            height={32}
                        />
                        <Image
                            className={classNames('hidden', {
                                'md:inline-block hidden': MAINNET,
                            })}
                            asset={'claystack-logo'}
                            width={120}
                            height={32}
                        />
                        <Image
                            className={classNames('hidden', {
                                'lg:!hidden !inline-block': !MAINNET,
                                hidden: MAINNET,
                            })}
                            asset={'claystack-logo-mobile'}
                            width={21}
                            height={32}
                        />
                        <Image
                            className={classNames('hidden', {
                                'lg:inline-block hidden': !MAINNET,
                            })}
                            asset={'claystack-victoria'}
                            width={186}
                            height={32}
                        />
                    </a>
                </Link>

                {isMissionHub ? (
                    <div className="relative flex items-center justify-end w-full gap-2 actions md:gap-4">
                        <MissionHubNav />
                        <NavSubmenu items={navItems} onClick={() => track('NAV-ICON-HAMBURGER')} />
                    </div>
                ) : (
                    <div className="relative flex items-center justify-between w-full gap-2 actions md:gap-4">
                        <AppNav isFromFtm={isFromFtm} />
                        <NavSubmenu items={navItems} onClick={() => track('NAV-ICON-HAMBURGER')} />
                    </div>
                )}
            </nav>
        </>
    );
};
