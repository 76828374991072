import {
    Network,
    NetworkConfig,
    NetworkFamily,
    NetworkFamilyConfig,
    SwapToken,
    Token,
    TokenConfig,
    TokenVocab,
} from './types';

const TokenAbi = () => import('@/utils/contracts/abi/Token.abi.json');
const CsToken = () => import('@/utils/contracts/abi/CsToken.abi.json');
const ClayGraph = () => import('@/utils/contracts/abi/ClayGraph.abi.json');
const ClayMatic = () => import('@/utils/contracts/abi/ClayMatic.abi.json');
const ClayExchange = () => import('@/utils/contracts/abi/ClayExchange.abi.json');
const ClayExchange_v1 = () => import('@/utils/contracts/abi/ClayExchange_v1.abi.json');
const ClayFaucet = () => import('@/utils/contracts/abi/ClayFaucet.abi.json');
const ClaySwap = () => import('@/utils/contracts/abi/ClaySwap.abi.json');
const ClayManager = () => import('@/utils/contracts/abi/ClayManager.abi.json');
const ClayFTM = () => import('@/utils/contracts/abi/ClayFTM.abi.json');

const TOKEN_STYLES: Record<Exclude<Token, 'MATIC_POLYGON'>, TokenVocab['styles']> = {
    MATIC: {
        backgroundColor: 'bg-[#24173C]',
        backgroundImage: '/images/matic--bg-card.png',
        buttonColor: 'bg-[#2b235c]',
    },
    GRT: {
        backgroundColor: 'bg-[#24173C]',
        backgroundImage: '/images/grt--bg-card.png',
        buttonColor: 'bg-[#03335f]',
    },
    FTM: {
        backgroundColor: 'bg-[#24173C]',
        backgroundImage: '/images/ftm--bg-card.png',
        buttonColor: 'bg-[#03335f]',
    },
};
const NETWORK_FAMILY_CONFIG: Record<NetworkFamily, NetworkFamilyConfig> = {
    ethereum: {
        name: 'Ethereum',
        icon: {
            asset: 'ethereum',
            height: 24,
            width: 16,
        },
    },
    polygon: {
        name: 'Polygon',
        icon: {
            asset: 'matic',
            height: 24,
            width: 24,
        },
    },
    fantom: {
        name: 'Fantom',
        icon: {
            asset: 'ftm',
            height: 24,
            width: 24,
        },
    },
};
export const NETWORK_CONFIG: Record<Network, NetworkConfig> = {
    goerli: {
        _id: 'goerli',
        explorer: 'https://goerli.etherscan.io/tx/',
        chainId: 5,
        name: 'Goerli',
        clayStackNode: 'https://nginx:ajs6583k85667@xaea-14.com',
        family: NETWORK_FAMILY_CONFIG.ethereum,
        currency: 'ETH',
    },
    rinkeby: {
        _id: 'rinkeby',
        explorer: 'https://rinkeby.etherscan.io/tx/',
        chainId: 4,
        name: 'Rinkeby',
        clayStackNode: `https://rinkeby.infura.io/v3/90f112e4496f4817a561dc440332f492`,
        family: NETWORK_FAMILY_CONFIG.ethereum,
        currency: 'ETH',
    },
    localhost: {
        _id: 'localhost',
        explorer: 'https://goerli.etherscan.io/tx/',
        chainId: 1337,
        name: 'Local',
        clayStackNode: 'http://127.0.0.1:8545',
        family: NETWORK_FAMILY_CONFIG.ethereum,
        currency: 'ETH',
    },
    mainnet: {
        _id: 'mainnet',
        explorer: 'https://etherscan.io/tx/',
        chainId: 1,
        name: 'Mainnet',
        clayStackNode: 'https://nginx:ajs6583k85667@xaea-14.com',
        family: NETWORK_FAMILY_CONFIG.ethereum,
        currency: 'ETH',
    },
    mumbai: {
        _id: 'mumbai',
        explorer: 'https://mumbai.polygonscan.com/tx/',
        chainId: 80001,
        name: 'Mumbai',
        clayStackNode: 'https://rpc-mumbai.matic.today',
        family: NETWORK_FAMILY_CONFIG.polygon,
        currency: 'MATIC',
    },
    polygon: {
        _id: 'polygon',
        explorer: 'https://polygonscan.com/tx/',
        chainId: 137,
        name: 'Polygon',
        clayStackNode: 'https://rpc-mainnet.matic.network',
        family: NETWORK_FAMILY_CONFIG.polygon,
        currency: 'MATIC',
    },
    fantom_testnet: {
        _id: 'fantom_testnet',
        explorer: 'https://testnet.ftmscan.com/tx/',
        chainId: 4002,
        name: 'Fantom testnet',
        clayStackNode: 'https://rpc-mainnet.matic.network',
        family: NETWORK_FAMILY_CONFIG.fantom,
        currency: 'FTM',
    },
    fantom: {
        _id: 'fantom',
        explorer: 'https://ftmscan.com/',
        chainId: 250,
        name: 'Fantom Opera',
        clayStackNode: 'https://rpc-mainnet.matic.network',
        family: NETWORK_FAMILY_CONFIG.fantom,
        currency: 'FTM',
    },
};
export const TOKEN_CONFIG: Record<Token, TokenConfig> = {
    MATIC: {
        testnet: NETWORK_CONFIG.goerli,
        mainnet: NETWORK_CONFIG.mainnet,
        vocab: {
            _id: 'MATIC',
            slug: 'matic',
            name: 'Polygon',
            symbol: 'MATIC',
            csSymbol: 'csMATIC',
            inMainnet: true,
            isChild: false,
            parentId: 'MATIC',
            isNative: false,
            isChartEnabled: true,
            styles: TOKEN_STYLES.MATIC,
            apr: 0.092179,
        },
        cgId: 'matic-network',
        assets: {
            MATIC: 'matic',
            csMATIC: 'cs-matic',
        },
        checkpointTime: {
            goerli: 1800, // 30 minutes https://wallet-dev.polygon.technology/staking/
            mainnet: 1800, // 30 minutes https://wallet.polygon.technology/staking/
        },
        address: {
            mainnet: {
                Token: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
                CsToken: '0x38b7Bf4eeCF3EB530b1529c9401FC37d2a71a912',
                ClayMain: '0x91730940DCE63a7C0501cEDfc31D9C28bcF5F905',
                ClayFaucet: '',
            },
            goerli: {
                Token: '0x499d11e0b6eac7c0593d8fb292dcbbf815fb29ae',
                CsToken: '0x3fb4601911871b635011aF01eDda5854F27560ce',
                ClayMain: '0xE29d3d4d72997b31Ccdf8188113c189f1106f6b8',
                ClayFaucet: '0x11Fe0b9b1a408f5D790C6Ea5666ee6F31306408F',
            },
            localhost: {
                Token: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
                CsToken: '0x2910E325cf29dd912E3476B61ef12F49cb931096',
                ClayMain: '0x7bc06c482DEAd17c0e297aFbC32f6e63d3846650',
                ClayFaucet: '0xCD8a1C3ba11CF5ECfa6267617243239504a98d90',
            },
        },
        abi: {
            mainnet: {
                Token: TokenAbi,
                CsToken,
                ClayMain: ClayMatic,
            },
            goerli: {
                Token: TokenAbi,
                CsToken,
                ClayMain: ClayMatic,
                ClayFaucet,
            },
            localhost: {
                Token: TokenAbi,
                CsToken,
                ClayMain: ClayMatic,
                ClayFaucet,
            },
        },
        version: {
            mainnet: 'v1',
            goerli: 'victoria',
            localhost: 'victoria',
        },
    },
    MATIC_POLYGON: {
        testnet: NETWORK_CONFIG.mumbai,
        mainnet: NETWORK_CONFIG.polygon,
        vocab: {
            _id: 'MATIC_POLYGON',
            slug: 'polygon',
            name: 'Polygon',
            symbol: 'MATIC',
            csSymbol: 'csMATIC',
            inMainnet: true,
            isChild: true,
            parentId: 'MATIC',
            isNative: true,
            isChartEnabled: true,
            styles: TOKEN_STYLES.MATIC,
            apr: 0.092179,
        },
        cgId: 'matic-network',
        assets: {
            MATIC: 'matic',
            csMATIC: 'cs-matic',
        },
        checkpointTime: {
            goerli: 1500, // 25 minutes https://wallet-dev.polygon.technology/staking/
            mainnet: 900, // 15 minutes https://wallet.polygon.technology/staking/
        },
        address: {
            mumbai: {
                CsTokenV1: '0x7c35c1bfd89bbde1c63a45b295a02a07e52e27cf',
                CsToken: '0x2eD77c504bF34D1d38fd9556D3982dF604f9c3f7',
                ClaySwap: '0x402c2Ed3d8Ba664e870ed119fBcbe9a6D5F46F0a',
                ClayMain: '0x08dA529F1842EF12D2daeDbE55194899cc22A6bd', // ClayManager
                ClayExchange: '0xADde4dFE9668ca6EA41AFA9805fAE7b9B21683B9',
            },
            polygon: {
                CsToken: '0x7ed6390f38d554B8518eF30B925b46972E768AF8',
                ClayMain: '0x376b467dFf007dD8d3f24404cAddff7F72257Fe4',
                ClaySwap: '0xD776098AeBD0525E00a2C0E77350Ad5e0BB582dF',
            },
        },
        abi: {
            mumbai: {
                Token: TokenAbi,
                CsToken,
                CsTokenV1: CsToken,
                ClayMain: ClayManager,
                ClayExchange,
                ClaySwap,
            },
            polygon: {
                Token: TokenAbi,
                CsToken,
                ClayMain: ClayExchange_v1,
                ClaySwap,
            },
        },
        version: {
            polygon: 'v1',
            mumbai: 'victoria',
        },
    },
    GRT: {
        testnet: NETWORK_CONFIG.rinkeby,
        mainnet: NETWORK_CONFIG.mainnet,
        vocab: {
            _id: 'GRT',
            slug: 'grt',
            name: 'Graph',
            symbol: 'GRT',
            csSymbol: 'csGRT',
            inMainnet: false,
            isChild: false,
            parentId: 'GRT',
            isNative: false,
            isChartEnabled: true,
            styles: TOKEN_STYLES.GRT,
            apr: 0.05, // TODO add public APR
        },
        cgId: 'matic-network',
        assets: { GRT: 'grt', csGRT: 'cs-grt' },
        checkpointTime: {
            rinkeby: 3_600, // 1 hour
            mainnet: 86_400, // 24 hours
        },
        address: {
            rinkeby: {
                Token: '0x54fe55d5d255b8460fb3bc52d5d676f9ae5697cd',
                CsToken: '0xb5bEA89ac64555FBa349088434A5Ca21236C23CC',
                ClayMain: '0x880C80C6739C05F9ddB8Bc2597B65d1EC9B92C10',
                ClayFaucet: '0x83B7cF23b047Df8b0c69649df43362631cbbEDbF',
            },
            localhost: {
                Token: '0xc944E90C64B2c07662A292be6244BDf05Cda44a7',
                CsToken: '0xA7918D253764E42d60C3ce2010a34d5a1e7C1398',
                ClayMain: '0x2E2Ed0Cfd3AD2f1d34481277b3204d807Ca2F8c2',
                ClayFaucet: '0xCD8a1C3ba11CF5ECfa6267617243239504a98d90',
            },
        },
        abi: {
            rinkeby: {
                Token: TokenAbi,
                CsToken,
                ClayMain: ClayGraph,
                ClayFaucet,
            },
            localhost: {
                Token: TokenAbi,
                CsToken,
                ClayMain: ClayGraph,
                ClayFaucet,
            },
        },
        version: {
            mainnet: 'v1',
            rinkeby: 'victoria',
            localhost: 'victoria',
        },
    },
    FTM: {
        testnet: NETWORK_CONFIG['fantom_testnet'],
        mainnet: NETWORK_CONFIG.mainnet,
        vocab: {
            _id: 'FTM',
            slug: 'ftm',
            name: 'FTM',
            symbol: 'FTM',
            csSymbol: 'csFTM',
            inMainnet: false,
            isChild: false,
            parentId: 'FTM',
            isNative: true,
            isChartEnabled: true,
            styles: TOKEN_STYLES.FTM,
            apr: 0.05, // TODO add public APR
        },
        cgId: 'fantom',
        assets: { FTM: 'ftm', csFTM: 'cs-ftm' },
        checkpointTime: {
            fantomTestnet: 1,
            mainnet: 1,
        },
        address: {
            fantom_testnet: {
                Token: '0x54fe55d5d255b8460fb3bc52d5d676f9ae5697cd',
                CsToken: '0x46609584b6022EF914C88ab0C8abf578a2B4E7A3',
                ClayMain: '0x56edDC7dDAA6508a2808a7D07D0F79923A4113aD',
                ClayFaucet: '0x83B7cF23b047Df8b0c69649df43362631cbbEDbF',
            },
            fantom: {
                Token: '0x54fe55d5d255b8460fb3bc52d5d676f9ae5697cd',
                CsToken: '0xb5bEA89ac64555FBa349088434A5Ca21236C23CC',
                ClayMain: '0x880C80C6739C05F9ddB8Bc2597B65d1EC9B92C10',
                ClayFaucet: '0x83B7cF23b047Df8b0c69649df43362631cbbEDbF',
            },
            localhost: {
                Token: '0x54fe55d5d255b8460fb3bc52d5d676f9ae5697cd',
                CsToken: '0xbdEd0D2bf404bdcBa897a74E6657f1f12e5C6fb6',
                ClayMain: '0x0165878A594ca255338adfa4d48449f69242Eb8F',
                ClayFaucet: '0xCD8a1C3ba11CF5ECfa6267617243239504a98d90',
            },
        },
        abi: {
            fantom_testnet: {
                Token: TokenAbi,
                CsToken,
                ClayMain: ClayFTM,
                ClayFaucet,
            },
            fantom: {
                Token: TokenAbi,
                CsToken,
                ClayMain: ClayFTM,
                ClayFaucet,
            },
            localhost: {
                Token: TokenAbi,
                CsToken,
                ClayMain: ClayFTM,
                ClayFaucet,
            },
        },
        version: {
            mainnet: 'v1',
            fantom_testnet: 'victoria',
            localhost: 'victoria',
        },
    },
};

export const RPC_URLS = {
    mainnet: [
        'https://mainnet.infura.io/v3/90f112e4496f4817a561dc440332f492',
        'https://mainnet.infura.io/v3/2ce17bba00d04226a02dc6d69fe9cb99',
        'https://mainnet.infura.io/v3/94a687f398b24310a4f6b48ee9d80869',
    ],
    localhost: ['http://127.0.0.1:8545'],
    goerli: [
        'https://nginx:ajs6583k85667@xaea-14.com',
        'https://goerli.infura.io/v3/90f112e4496f4817a561dc440332f492',
        'https://goerli.infura.io/v3/2ce17bba00d04226a02dc6d69fe9cb99',
        'https://goerli.infura.io/v3/94a687f398b24310a4f6b48ee9d80869',
        'https://eth-goerli.gateway.pokt.network/v1/lb/61aa25d23914cb00391c4c06',
        'https://eth-goerli.gateway.pokt.network/v1/lb/61aa25ee3914cb00391c4c7f',
    ],
    mumbai: [
        'https://matic-mumbai.chainstacklabs.com',
        'https://rpc-mumbai.maticvigil.com',
        'https://matic-testnet-archive-rpc.bwarelabs.com',
        'https://rpc-mumbai.matic.today', //Moved to last in the list since this is unreliable
    ],
    polygon: [
        'https://rpc-mainnet.matic.network',
        'https://matic-mainnet.chainstacklabs.com',
        'https://rpc-mainnet.maticvigil.com',
        'https://rpc-mainnet.matic.quiknode.pro',
        'https://matic-mainnet-full-rpc.bwarelabs.com',
    ],
    rinkeby: [
        'https://r.xaea-14.com/',
        'https://rinkeby.infura.io/v3/90f112e4496f4817a561dc440332f492',
        'https://rinkeby.infura.io/v3/2ce17bba00d04226a02dc6d69fe9cb99',
        'https://rinkeby.infura.io/v3/94a687f398b24310a4f6b48ee9d80869',
    ],
    fantom_testnet: ['https://rpc.testnet.fantom.network'],
    fantom: ['https://rpc2.fantom.network'],
};
export const SWAP_TOKEN_LIST: SwapToken[] = [
    {
        token: null,
        image: 'matic',
        label: 'MATIC',
        decimals: 18,
        address: {
            mumbai: null,
            polygon: '',
        },
        isOnlyMainnet: false,
    },
    {
        token: 'W_MATIC',
        image: 'swap--wmatic',
        label: 'wMATIC',
        decimals: 18,
        address: {
            mumbai: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
            polygon: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        },
        isOnlyMainnet: false,
    },
    {
        token: 'miMATIC',
        image: 'swap--mai',
        label: 'MAI',
        decimals: 18,
        address: {
            polygon: '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
        },
        isOnlyMainnet: true,
    },
    {
        token: 'WETH',
        image: 'swap--ethereum',
        label: 'ETH',
        decimals: 18,
        address: {
            mumbai: '0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa',
            polygon: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
        },
        isOnlyMainnet: false,
    },
    {
        token: 'USDT',
        image: 'swap--usdt',
        label: 'USDT',
        decimals: 6,
        address: {
            mumbai: '0x3813e82e6f7098b9583FC0F33a962D02018B6803',
            polygon: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
        },
        isOnlyMainnet: false,
    },
    {
        token: 'USDC',
        image: 'swap--usdc',
        label: 'USDC',
        decimals: 6,
        address: {
            polygon: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        },
        isOnlyMainnet: true,
    },
    {
        token: 'WBTC',
        image: 'swap--wbtc',
        label: 'WBTC',
        decimals: 18,
        address: {
            polygon: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
        },
        isOnlyMainnet: true,
    },
    {
        token: 'SAND',
        image: 'swap--sand',
        label: 'SAND',
        decimals: 18,
        address: {
            mumbai: '0xE03489D4E90b22c59c5e23d45DFd59Fc0dB8a025',
            polygon: '0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683',
        },
        isOnlyMainnet: false,
    },
    {
        token: 'DAI',
        image: 'swap--dai',
        label: 'DAI',
        decimals: 18,
        address: {
            mumbai: '0xcB1e72786A6eb3b44C2a2429e317c8a2462CFeb1',
            polygon: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
        },
        isOnlyMainnet: false,
    },
    {
        token: 'QI',
        image: 'swap--qi',
        label: 'QI',
        decimals: 18,
        address: {
            polygon: '0x580A84C73811E1839F75d86d75d88cCa0c241fF4',
        },
        isOnlyMainnet: true,
    },
    {
        token: 'DG',
        image: 'swap--dg',
        label: 'DG',
        decimals: 18,
        address: {
            polygon: '0xef938b6da8576a896f6E0321ef80996F4890f9c4',
        },
        isOnlyMainnet: true,
    },
];
export const TOKEN_LIST = Object.keys(TOKEN_CONFIG) as Token[];
export const TOKEN_SLUGS = Object.values(TOKEN_CONFIG).map((t) => t.vocab);
